import React from 'react'
import styled from 'styled-components'

const StyledForm = styled.form`
  input,
  textarea {
    background: rgba(45, 45, 45, 1) 0% 0% no-repeat padding-box;
    border-radius: 3px;
    border: none;
    padding: 8px 10px;
    display: block;
    width: 100%;
    color: #fff;
  }
  label {
    color: #fff;
    margin-bottom: 3px;
    display: block;
  }
`

const Button = styled.button`
  background: rgba(45, 45, 45, 1);
  border: none;
  outline: none;
  font-weight: bold;
  color: #1ca0f2;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 5px;
`

const ContactForm = () => (
  <StyledForm name="Contact Form" method="POST" data-netlify="true">
    <input type="hidden" name="form-name" value="Contact Form" />
    <p>
      <label htmlFor="name">Name</label>
      <input type="text" name="name" />
    </p>
    <p>
      <label htmlFor="email">Email</label>
      <input type="email" name="email" />
    </p>
    <p>
      <label htmlFor="message">Message</label>
      <textarea name="message" rows={5}></textarea>
    </p>

    <p>
      <Button type="submit">Send</Button>
    </p>
  </StyledForm>
)

export default ContactForm
