import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import RichTextRenderer from '../components/rich-text-renderer'
import {
  SectionHeader,
  Section,
  SectionContainer,
  SectionDescription,
  SectionContent,
} from '../styles/styles'
import ContactForm from '../components/contact-form'

const BTW = styled.div`
  margin-top: 20px;
  color: rgba(163, 165, 105, 1);
  font: regular 16px/22px 'Roboto';
  letter-spacing: 0;
`

const Contact = ({ data }) => {
  const {
    backgroundImage,
    contactInfo,
    sectionSubtitle,
    title,
    sectionTitle,
  } = data.contentfulContactPage
  return (
    <Layout bgImage={backgroundImage}>
      <SEO title={title} />
      <h1>{title}</h1>
      <SectionHeader>{sectionTitle}</SectionHeader>
      <Section>
        <SectionContainer>
          <SectionDescription>
            <h4>{sectionSubtitle}</h4>
            <RichTextRenderer json={contactInfo.json} />
          </SectionDescription>
          <SectionContent>
            <h4>Send us a message.</h4>
            <ContactForm />
            {/* <BTW>
              <div
                style={{
                  fontSize: '1.4em',
                  fontWeight: 'bold',
                  marginBottom: 5,
                }}
              >
                BTW
              </div>{' '}
              This goes to a real person. They'll get back to you as soon as
              they can.
            </BTW> */}
          </SectionContent>
        </SectionContainer>
      </Section>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query ContactQuery {
    contentfulContactPage {
      backgroundImage {
        fluid(maxWidth: 1024) {
          src
          srcSet
          sizes
          srcSetWebp
          srcWebp
          base64
          aspectRatio
        }
      }
      contactInfo {
        json
      }
      sectionSubtitle
      sectionTitle
      title
    }
  }
`
